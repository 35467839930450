<template>
  <div class="flex h-8 w-full mt-8 max-w-md" id="search-bar">
    <input
      class="flex-grow border border-blue rounded-sm "
      v-model="query"
      placeholder="Search for model name / no."
    />
    <button @click="search" class="w-8 ml-4 border border-blue rounded-sm">
      <img src="@/assets/btn_search.svg" alt="search icon" />
    </button>
    <button @click="search" class="w-8 ml-4 border border-blue rounded-sm">
      <img src="@/assets/btn_filter.svg" alt="filter icon" />
    </button>
  </div>
</template>

<script lang="js">

export default {
props: ["products"],
components: { },
  methods: {
    search() {
      console.log(this.query, this.products);
    }
  },
  data() {
    return {
      query: ''
      }
  },
};
</script>
