<template> <div></div></template>

<script lang="js">

export default {
props: ["products"],
  methods: {
    search() {
      console.log(this.products);
    }
  },
  data() {
    return {
      query: ''
      }
  },
};
</script>
