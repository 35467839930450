<template>
  <div id="product-grid" class="grid grid-cols-2 lg:grid-cols-3 mt-8">
    <ProductsGridItem
      v-for="product in products"
      :key="product.id"
      :product="product.Product"
    />
  </div>
</template>

<script lang="js">
import ProductsGridItem from "@/components/ProductsGridItem.vue";

export default {
  props: ["products"],
  components: {
    ProductsGridItem,
  },
};
</script>
