<template>
  <div class="flex flex-col max-w-screen-lg">
    <SearchBar :products="products" class="lg:self-end" />
    <FilterMenu />
    <ProductsGrid :products="products" />
  </div>
</template>

<script lang="js">
import ProductsGrid from "@/components/ProductsGrid.vue";
import SearchBar from "@/components/SearchBar.vue";
import FilterMenu from "@/components/FilterMenu.vue"
import Strapi from "@/strapi.js"

export default{
name: "Products Page",
  components: {
    ProductsGrid,
    SearchBar,
    FilterMenu
  },
  data() {
    return {
    products: [],
    error: null};
  },
  async mounted() {
  Strapi.getProducts()
   .then(products => {
    this.products = products;
   })
   .catch(error=>console.log(error))
}
}
</script>
