<template>
  <div class="p-2 object-center flex flex-col max-w-sm">
    <router-link v-bind:to="'/products/' + product.id">
      <div class="p-2 border border-cyan">
        <img class="w-full" v-bind:src="url + product.tableimg.url" alt="" />
        <h3 class="">{{ product.name }}</h3>
      </div>
    </router-link>
    <div>
      <p class="p-2">{{ product.description }}</p>
    </div>
  </div>
</template>

<script lang="js">

export default {
  props: ["product"],
  data() {
    return {url: process.env.VUE_APP_STRAPI_URL}
  }
}
</script>
